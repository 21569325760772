import React from 'react';  
import './footer.css';  

import siteLogo from './logo2024-nobg.png';
  
const Footer = () => {  
  return (  
   <footer>
    <div class="footer-container">
        <div class="footer-column logo-column">
            <img src={siteLogo} alt="Site Logo" className="site-logo-icon"/>
        </div>
        <div class="footer-column menu-column">
            <ul>
                <li><a href="#">About Us</a></li>
                <li><a href="#">Why Choose Us</a></li>
                <li><a href="#">Our Services</a></li>
                <li><a href="#">Our Vision</a></li>
            </ul>
        </div>
        <div class="footer-column contact-column">
            <p>Contact Information:</p>
            <p>Email: customercare@motorpdi.com</p>
            <p>Phone: 91297-81297</p>
            <p>Address: Noida, UttarPradesh (201301)</p>
        </div>
    </div>
    <div class="footer-bottom">
        © 2024 MotorPDI. All rights reserved.
    </div>
</footer>
  );  
};  
  
export default Footer;


/*

**About Us**

At [Company Name], we specialize in providing high-quality Pre-Delivery Inspections (PDI) for the automotive industry. Our mission is to ensure that every vehicle we service meets the highest standards of safety, quality, and functionality before it reaches the end customer. We understand the importance of the final checks and adjustments that turn a newly manufactured vehicle into a ready-to-drive car, and we’re committed to delivering excellence in every aspect of this process.

### Why Choose Us

Our experienced team of technicians, quality inspectors, and support staff bring expertise and dedication to every vehicle we inspect. We collaborate closely with manufacturers, dealerships, and fleet operators to ensure each car is thoroughly inspected, fine-tuned, and road-ready. Our inspection process covers all essential elements, from mechanical systems and electrical components to the overall aesthetic quality, giving our clients the confidence that each vehicle meets the highest industry standards.

### Our Services

We offer comprehensive PDI services tailored to meet the unique needs of our clients:
- **Mechanical and Electrical System Checks:** Verifying engine performance, transmission, brakes, lights, and other critical components.
- **Exterior and Interior Inspections:** Ensuring paintwork, trim, upholstery, and accessories are in flawless condition.
- **Software Updates and Configuration:** Installing the latest software and adjusting in-car systems to provide optimal functionality.
- **Road Testing and Final Adjustments:** Conducting test drives and fine-tuning settings for a seamless driving experience.

### Our Commitment to Quality and Safety

At [Company Name], we believe that quality and safety are paramount. Every car that passes through our hands is inspected meticulously using standardized processes, advanced diagnostic tools, and quality control protocols. We pride ourselves on our attention to detail and the rigorous standards we uphold, allowing us to deliver cars that exceed customer expectations.

### Our Vision

Our goal is to set the industry benchmark for PDI services, continuously improving our processes and adopting new technologies that enhance the quality and efficiency of our inspections. We’re driven by a passion for excellence and a commitment to supporting our clients as they build trust and satisfaction with every vehicle delivered.

With [Company Name], you can trust that each car is inspected with precision, care, and expertise—ready to meet the road and exceed expectations.

*/
