import React, {useState, useEffect} from 'react';  
import logo from './logo2024.png';
import arrowImage from './arrows.png';
import callButton from './callButton.png';
import locationLogo from './locationLogo.png'
import './header.css';  
  
function Header() {
  const [scrolled, setScrolled] = useState(false);  
  
  useEffect(() => {  
   const handleScroll = () => {  
    const scrollTop = window.scrollY;  
    if (scrollTop > 0) {  
      setScrolled(true);  
    } else {  
      setScrolled(false);  
    }  
   };  
   window.addEventListener('scroll', handleScroll);  
   return () => {  
    window.removeEventListener('scroll', handleScroll);  
   };  
  }, []);

  return (  
   <header className="header">  
    <div className="header-logo">  
      <img src={logo} alt="Motor pdi Logo" />  
    </div>  
    <nav className="header-nav">  
      <ul>  
       <li>  
        <a href="#">Home</a>  
       </li>  
       <li>  
        <a href="#">Checklist</a>  
       </li>  
       <li>  
        <a href="#">Services</a>  
       </li>
      </ul>  
    </nav>
              <div className="right-section"> {/* Wrap the right section with the new class */} 
                <div className="contact-info">
                  <span class="text"> <img src={callButton} alt="Call button" class="call-button-icon" />91297-81297</span>
                  <div class="button-container">  
                    <button className="book-pdi-btn">
                    Book My PDI
                    <img src={arrowImage} alt="Arrow Icon" class="arrow-icon" />
                    </button>
                  </div>
                </div>
                <div className="location-info">  
                  <i className="fas fa-map-marker-alt"></i>  
                  <span>Delhi-NCR <img src={locationLogo} alt="Location Logo" class="location-logo-icon" /> </span>  
                </div>
              </div>
   </header>  
  );  
}  
  
export default Header;
